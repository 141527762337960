import React from "react";
import { ONDC_COLORS } from "../colors";

export default function Logout(props) {
  const {
    width = "20",
    height = "20",
    color = ONDC_COLORS.ACCENTCOLOR,
  } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 26.794 27.308"
    >
      <path
        data-name="Path 50"
        d="M128.885 58.478v5.561a7.325 7.325 0 0 1-.009.866c.016-.109.031-.22.043-.329a1.074 1.074 0 0 1-.056.2c.04-.1.084-.2.124-.3a1.133 1.133 0 0 1-.124.214l.2-.251a1 1 0 0 1-.174.171l.251-.2a1.008 1.008 0 0 1-.177.1c.1-.04.2-.084.3-.124a1.112 1.112 0 0 1-.2.056c.109-.016.22-.031.329-.043a3.681 3.681 0 0 1-.453.009H117.71c-.521 0-1.043.009-1.564 0a1.1 1.1 0 0 1-.143-.009c.109.016.22.031.329.043a1.112 1.112 0 0 1-.2-.056c.1.04.2.084.3.124a1.007 1.007 0 0 1-.177-.1l.251.2a.906.906 0 0 1-.174-.171l.2.251a1.132 1.132 0 0 1-.124-.214c.04.1.084.2.124.3a1.144 1.144 0 0 1-.056-.2c.016.109.031.22.043.329a6.186 6.186 0 0 1-.009-.72V41.719a1.354 1.354 0 0 1 .009-.158c-.016.109-.031.22-.043.329a1.112 1.112 0 0 1 .056-.2c-.04.1-.084.2-.124.3a1.084 1.084 0 0 1 .124-.208l-.2.251a1.013 1.013 0 0 1 .143-.14l-.251.2a1.008 1.008 0 0 1 .211-.121c-.1.04-.2.084-.3.124a1.1 1.1 0 0 1 .2-.053c-.109.016-.22.031-.329.043a3.668 3.668 0 0 1 .453-.009h11.224c.521 0 1.043-.009 1.564 0a1.1 1.1 0 0 1 .143.009 9.253 9.253 0 0 0-.329-.043 1.1 1.1 0 0 1 .2.053c-.1-.04-.2-.084-.3-.124a1.028 1.028 0 0 1 .211.121l-.251-.2a1.012 1.012 0 0 1 .143.14l-.2-.251a1 1 0 0 1 .124.208c-.04-.1-.084-.2-.124-.3a1.112 1.112 0 0 1 .056.2 9.253 9.253 0 0 1-.043-.329 6.076 6.076 0 0 1 .009.714v5.741a1.241 1.241 0 1 0 2.483 0v-5.377c0-.344.022-.7 0-1.04a2.116 2.116 0 0 0-.658-1.437 2.205 2.205 0 0 0-1.446-.571h-13.1a2.1 2.1 0 0 0-2.141 2.116v23.049a2.181 2.181 0 0 0 .633 1.533 2.154 2.154 0 0 0 1.514.611h13.1a2.159 2.159 0 0 0 2.11-2.126c.006-.335 0-.667 0-1v-5.3a1.255 1.255 0 0 0-1.241-1.241 1.276 1.276 0 0 0-1.254 1.247z"
        transform="translate(-114.027 -39.592)"
        style={{ fill: color }}
      />
      <path
        data-name="Path 51"
        d="M224.238 260.814h15.895c.732 0 1.468.009 2.2 0h.031a1.241 1.241 0 1 0 0-2.483h-15.895c-.732 0-1.468-.009-2.2 0h-.031a1.241 1.241 0 0 0 0 2.483z"
        transform="translate(-216.812 -245.915)"
        style={{ fill: color }}
      />
      <path
        data-name="Path 52"
        d="m464.589 181.813 1.5 1.5 2.389 2.389.552.552V184.5l-1.5 1.5-2.389 2.389-.552.552a1.242 1.242 0 0 0 1.756 1.756l1.5-1.5 2.389-2.389.552-.552a1.261 1.261 0 0 0 0-1.756l-1.5-1.5-2.389-2.389-.552-.552a1.242 1.242 0 0 0-1.756 1.756z"
        transform="translate(-444.36 -171.749)"
        style={{ fill: color }}
      />
    </svg>
  );
}
